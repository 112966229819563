import React from 'react';
import {Image, Text, TextButton, ImageAspectRatioPresets} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {classes} from './StoreNameAndLogo.st.css';
import {CheckoutHeader} from '@wix/wixstores-graphql-schema-node';
import {SPECS} from '../../constants';
import {useExperiments} from '@wix/yoshi-flow-editor';

export interface StoreNameAndLogoProps {
  continueShoppingUrl?: string;
  clickOnContinueShopping?: () => void;
  logoUrl?: string;
  storeName?: string;
  checkoutHeader?: CheckoutHeader;
  isOneColumnView?: boolean;
}

export enum StoreNameAndLogoDataHooks {
  root = 'StoreNameAndLogoDataHooks.root',
  logo = 'StoreNameAndLogoDataHooks.logo',
  storeName = 'StoreNameAndLogoDataHooks.storeName',
  title = 'StoreNameAndLogoDataHooks.title',
}

export enum Alignment {
  SIDE = 'SIDE',
  CENTER = 'CENTER',
}

export enum LogoSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export const StoreNameAndLogo = ({
  continueShoppingUrl,
  clickOnContinueShopping,
  logoUrl,
  storeName,
  checkoutHeader,
  isOneColumnView,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
StoreNameAndLogoProps) => {
  const localKeys = useLocaleKeys();

  const shouldUseCheckoutHeaderOnCheckout = !!checkoutHeader;
  const shouldShowLogo = !!checkoutHeader?.logo?.size;
  const shouldShowStoreName = !shouldShowLogo;
  const alignment = checkoutHeader?.alignment;
  const shouldDisplayCheckoutLabel = checkoutHeader?.displayCheckoutLabel;
  const logoSize = checkoutHeader?.logo?.size;
  const {experiments} = useExperiments();
  const shouldChangeCheckoutLogoH1TagName = experiments.enabled(SPECS.CheckoutLogoH1TagName);

  const logoHeight = () => {
    /* istanbul ignore else */
    /* istanbul ignore next */
    if (logoSize === LogoSize.MEDIUM) {
      return isOneColumnView ? 56 : 80;
    }
    /* istanbul ignore next */
    if (logoSize === LogoSize.LARGE) {
      return isOneColumnView ? 80 : 148;
    }
    /* istanbul ignore next */
    return isOneColumnView ? 26 : 56;
  };

  const checkoutTitle = () => {
    if ((!storeName && shouldShowStoreName) || (!logoUrl && shouldShowLogo)) {
      return localKeys.checkout.header_default_title();
    }
    if (shouldShowLogo && !shouldDisplayCheckoutLabel) {
      return undefined;
    }
    return localKeys.checkout.header.store_title_suffix();
  };

  return shouldUseCheckoutHeaderOnCheckout ? (
    <TextButton
      data-hook={StoreNameAndLogoDataHooks.root}
      className={
        alignment === Alignment.CENTER
          ? /* istanbul ignore next */ classes.headerTitleAndLogoCenter
          : classes.headerTitleAndLogoSide
      }
      contentClassName={classes.headerTitleAndLogoContent}
      as={'a'}
      onClick={() => clickOnContinueShopping?.()}
      href={continueShoppingUrl}>
      {shouldShowLogo && (
        <Image
          data-hook={StoreNameAndLogoDataHooks.logo}
          src={logoUrl}
          width={logoHeight()}
          height={logoHeight()}
          alt={localKeys.checkout.page.siteLogo_altTextDefault()}
        />
      )}
      {shouldChangeCheckoutLogoH1TagName ? (
        <span className={classes.headerTitleAndStoreName}>
          {shouldShowStoreName && (
            <Text tagName="h1" className={classes.headerStoreName} data-hook={StoreNameAndLogoDataHooks.storeName}>
              {storeName}
            </Text>
          )}
          <Text tagName="h1" className={classes.headerTitle} data-hook={StoreNameAndLogoDataHooks.title}>
            {checkoutTitle()}
          </Text>
        </span>
      ) : (
        <span>
          {shouldShowStoreName && (
            <Text className={classes.headerStoreName} data-hook={StoreNameAndLogoDataHooks.storeName}>
              {storeName}
            </Text>
          )}
          <Text className={classes.headerTitle} data-hook={StoreNameAndLogoDataHooks.title}>
            {checkoutTitle()}
          </Text>
        </span>
      )}
    </TextButton>
  ) : (
    <TextButton
      data-hook={StoreNameAndLogoDataHooks.root}
      className={classes.headerTitleAndLogo}
      contentClassName={classes.headerTitleAndLogoContent}
      as={'a'}
      onClick={() => clickOnContinueShopping?.()}
      href={continueShoppingUrl}>
      {logoUrl && (
        <Image
          data-hook={StoreNameAndLogoDataHooks.logo}
          className={classes.logo}
          src={logoUrl}
          aspectRatio={ImageAspectRatioPresets.square}
          fluid={true}
          alt={localKeys.checkout.page.siteLogo_altTextDefault()}
        />
      )}
      {shouldChangeCheckoutLogoH1TagName ? (
        <span className={classes.headerTitleAndStoreName}>
          {storeName && !logoUrl && (
            <Text tagName="h1" className={classes.headerStoreName} data-hook={StoreNameAndLogoDataHooks.storeName}>
              {storeName}
            </Text>
          )}
          <Text tagName="h1" className={classes.headerTitle} data-hook={StoreNameAndLogoDataHooks.title}>
            {storeName || logoUrl
              ? localKeys.checkout.header.store_title_suffix()
              : localKeys.checkout.header_default_title()}
          </Text>
        </span>
      ) : (
        <span>
          {storeName && !logoUrl && (
            <Text className={classes.headerStoreName} data-hook={StoreNameAndLogoDataHooks.storeName}>
              {storeName}
            </Text>
          )}
          <Text className={classes.headerTitle} data-hook={StoreNameAndLogoDataHooks.title}>
            {storeName || logoUrl
              ? localKeys.checkout.header.store_title_suffix()
              : localKeys.checkout.header_default_title()}
          </Text>
        </span>
      )}
    </TextButton>
  );
};
